import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';
import { method } from 'lodash';

export const subscriptionApi = createApi({
  reducerPath: 'subscriptions',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['Subscriptions'],
  endpoints: (builder) => ({
    getAllSubscriptions: builder.query({
      query: ({ pageno, limit, search }) =>
        `subscription-plans?pageno=${pageno + 1}&limit=${limit}&search=${search}`,
      transformResponse: (res) => ({ data: res.body, total_length: res.total_pages }),
      providesTags: ['Subscriptions'],
    }),

    addNewSubscriptions: builder.mutation({
      query: (updatedData) => ({
        url: `/subscription-plans`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: updatedData,
      }),
      invalidatesTags: ['Subscriptions'],
    }),

    updateNewSubscriptions: builder.mutation({
      query: (data) => ({
        url: `subscription-plans/${data._id}`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: data.updatedData,
      }),
      invalidatesTags: ['Subscriptions'],
    }),
  }),
});

export const {
  useGetAllSubscriptionsQuery,
  useAddNewSubscriptionsMutation,
  useUpdateNewSubscriptionsMutation,
} = subscriptionApi;
