import { createApi } from "@reduxjs/toolkit/query/react";
import { createCustomFetchBaseQuery } from "../baseQuery";

export const supportApi = createApi({
    reducerPath: "support",
    baseQuery: createCustomFetchBaseQuery(),
    endpoints: (builder) => ({
        getAllContactMessages: builder.query({
      query: ({ pageno, limit, search }) =>{console.log({pageno,limit,search}); return`admin/contact?pageno=${pageno + 1}&limit=${limit}&search=${search}`},
    //   transformResponse: (res) => ({ total_pages: res.total_pages, res }),
      providesTags: ['Support'],
        }),
    }),
});

export const {
    useGetAllContactMessagesQuery
} = supportApi;
