import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const equipmentOrdersApi = createApi({
  reducerPath: 'equipmentOrders',
  baseQuery: createCustomFetchBaseQuery(),
  endpoints: (builder) => ({
    getAllEquipmentOrders: builder.query({
      query: ({ pageno, limit, search }) =>
        `/order?pageno=${pageno + 1}&limit=${limit}&search=${search}`,
      transformResponse: (res) => ({ total_pages: res.total_pages, data: res.body }),
      providesTags: ['Orders'],
    }),

    updateOrderDeliveryStatus: builder.mutation({
      query: ({_id, updateData}) => (
        console.log('data on api', updateData),{
        url: `order/updatestatus/${_id}`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: updateData,
      }),
      invalidatesTags : ['Orders']
    }),

  }),
});

export const { useGetAllEquipmentOrdersQuery, useUpdateOrderDeliveryStatusMutation } = equipmentOrdersApi;
