import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { authApi } from './Reducer/auth';
import userReducer from './slices/userSlice';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { productsApi } from './Reducer/products';
import { categoryApi } from './Reducer/category';
import { customersApi } from './Reducer/customers';
import { agentsApi } from './Reducer/agents';
import { serviceApi } from './Reducer/service';
import { equipmentApi } from './Reducer/equipment';
import { bookingApi } from './Reducer/booking';
import { FileApi } from './Reducer/file';
import { equipmentOrdersApi } from './Reducer/equipmentOrders';
import { specialOffersApi } from './Reducer/specialOffers';
import { FaqsApi } from './Reducer/faqs';
import { SettingsApi } from './Reducer/settings';
import { supportApi } from './Reducer/support';
import { dashboardApi } from './Reducer/dashboard';
import { subscriptionApi } from './Reducer/subscriptions';

// Define the persist configuration
const persistConfig = {
  key: 'root',
  storage,
  // whitelist: ['user'],
};

// Create a persisted reducer for the user slice
const persistedUserReducer = persistReducer(persistConfig, userReducer);

// Create the Redux store with the persisted reducer for the user slice
export const store = configureStore({
  reducer: {
    [categoryApi.reducerPath]: categoryApi.reducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [customersApi.reducerPath]: customersApi.reducer,
    [agentsApi.reducerPath]: agentsApi.reducer,
    [serviceApi.reducerPath]: serviceApi.reducer,
    [equipmentApi.reducerPath]: equipmentApi.reducer,
    [bookingApi.reducerPath]: bookingApi.reducer,
    [FileApi.reducerPath]: FileApi.reducer,
    [equipmentOrdersApi.reducerPath]: equipmentOrdersApi.reducer,
    [specialOffersApi.reducerPath]: specialOffersApi.reducer,
    [FaqsApi.reducerPath]: FaqsApi.reducer,
    [SettingsApi.reducerPath]: SettingsApi.reducer,
    [supportApi.reducerPath]: supportApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [subscriptionApi.reducerPath]: subscriptionApi.reducer,

    [authApi.reducerPath]: authApi.reducer,
    user: persistedUserReducer, // Use the persisted user reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      productsApi.middleware,
      authApi.middleware,
      categoryApi.middleware,
      customersApi.middleware,
      agentsApi.middleware,
      serviceApi.middleware,
      equipmentApi.middleware,
      bookingApi.middleware,
      FileApi.middleware,
      equipmentOrdersApi.middleware,
      specialOffersApi.middleware,
      FaqsApi.middleware,
      SettingsApi.middleware,
      supportApi.middleware,
      dashboardApi.middleware,
      subscriptionApi.middleware
    ),
});

// Initialize Redux Persist
export const persistor = persistStore(store);

// Setup listeners
setupListeners(store.dispatch);
