import { createApi } from "@reduxjs/toolkit/query/react";
import { createCustomFetchBaseQuery } from "../baseQuery";

export const dashboardApi = createApi({
  reducerPath: "dashboard",
  baseQuery: createCustomFetchBaseQuery(),
  endpoints: (builder) => ({
    getDashboard: builder.query({
      query: () => `/dashboard`,
      transformResponse: (res) => res.body,
    }),
  }),
});

export const {
  useGetDashboardQuery,
} = dashboardApi;
