import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// Define a function that returns a configured fetchBaseQuery with token implementation;
export const createCustomFetchBaseQuery = () => fetchBaseQuery({
  baseUrl: process.env.REACT_APP_HOST_API,
  prepareHeaders: (headers, { getState }) => {

    let user=getState()?.user?.user

    let token=user?user?.accesstoken:'';

    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }

    return headers
  },
});
