import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
const OverviewBookingPage = lazy(() => import('src/pages/dashboard/booking'));
const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));
// ORDER
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
// Booking
const BookingProfilePage = lazy(() => import('src/pages/dashboard/booking/profile'));
const BookingCardsPage = lazy(() => import('src/pages/dashboard/booking/cards'));
const BookingListPage = lazy(() => import('src/pages/dashboard/booking/list'));
const BookingAccountPage = lazy(() => import('src/pages/dashboard/booking/account'));
const BookingCreatePage = lazy(() => import('src/pages/dashboard/booking/new'));
const BookingEditPage = lazy(() => import('src/pages/dashboard/booking/edit'));

// Agents
const AgentsProfilePage = lazy(() => import('src/pages/dashboard/agents/profile'));
const AgentsCardsPage = lazy(() => import('src/pages/dashboard/agents/cards'));
const AgentsListPage = lazy(() => import('src/pages/dashboard/agents/list'));
const AgentsAccountPage = lazy(() => import('src/pages/dashboard/agents/account'));
const AgentsCreatePage = lazy(() => import('src/pages/dashboard/agents/new'));
const AgentsEditPage = lazy(() => import('src/pages/dashboard/agents/edit'));

// Services
const ServicesProfilePage = lazy(() => import('src/pages/dashboard/services/profile'));
const ServicesCardsPage = lazy(() => import('src/pages/dashboard/services/cards'));
const ServicesListPage = lazy(() => import('src/pages/dashboard/services/list'));
const ServicesAccountPage = lazy(() => import('src/pages/dashboard/services/account'));
const ServicesCreatePage = lazy(() => import('src/pages/dashboard/services/new'));
const ServicesEditPage = lazy(() => import('src/pages/dashboard/services/edit'));

// Category
const CategoryProfilePage = lazy(() => import('src/pages/dashboard/category/profile'));
const CategoryCardsPage = lazy(() => import('src/pages/dashboard/category/cards'));
const CategoryListPage = lazy(() => import('src/pages/dashboard/category/list'));
const CategoryAccountPage = lazy(() => import('src/pages/dashboard/category/account'));
const CategoryCreatePage = lazy(() => import('src/pages/dashboard/category/new'));
const CategoryEditPage = lazy(() => import('src/pages/dashboard/category/edit'));

// Customer
const CustomerProfilePage = lazy(() => import('src/pages/dashboard/customer/profile'));
const CustomerCardsPage = lazy(() => import('src/pages/dashboard/customer/cards'));
const CustomerListPage = lazy(() => import('src/pages/dashboard/customer/list'));
const CustomerAccountPage = lazy(() => import('src/pages/dashboard/customer/account'));
const CustomerCreatePage = lazy(() => import('src/pages/dashboard/customer/new'));
const CustomerEditPage = lazy(() => import('src/pages/dashboard/customer/edit'));
// Business
const BusinessProfilePage = lazy(() => import('src/pages/dashboard/business/profile'));
const BusinessCardsPage = lazy(() => import('src/pages/dashboard/business/cards'));
const BusinessListPage = lazy(() => import('src/pages/dashboard/business/list'));
const BusinessAccountPage = lazy(() => import('src/pages/dashboard/business/account'));
const BusinessCreatePage = lazy(() => import('src/pages/dashboard/business/new'));
const BusinessEditPage = lazy(() => import('src/pages/dashboard/business/edit'));

// Transfer
const TransferProfilePage = lazy(() => import('src/pages/dashboard/transfer/profile'));
const TransferCardsPage = lazy(() => import('src/pages/dashboard/transfer/cards'));
const TransferListPage = lazy(() => import('src/pages/dashboard/transfer/list'));
const TransferAccountPage = lazy(() => import('src/pages/dashboard/transfer/account'));
const TransferCreatePage = lazy(() => import('src/pages/dashboard/transfer/new'));
const TransferEditPage = lazy(() => import('src/pages/dashboard/transfer/edit'));

// Equipments
const EquipmentsProfilePage = lazy(() => import('src/pages/dashboard/equipments/profile'));
const EquipmentsCardsPage = lazy(() => import('src/pages/dashboard/equipments/cards'));
const EquipmentsListPage = lazy(() => import('src/pages/dashboard/equipments/list'));
const EquipmentsAccountPage = lazy(() => import('src/pages/dashboard/equipments/account'));
const EquipmentsCreatePage = lazy(() => import('src/pages/dashboard/equipments/new'));
const EquipmentsEditPage = lazy(() => import('src/pages/dashboard/equipments/edit'));

// Subscription
const SubscriptionProfilePage = lazy(() => import('src/pages/dashboard/subscription/profile'));
const SubscriptionCardsPage = lazy(() => import('src/pages/dashboard/subscription/cards'));
const SubscriptionListPage = lazy(() => import('src/pages/dashboard/subscription/list'));
const SubscriptionAccountPage = lazy(() => import('src/pages/dashboard/subscription/account'));
const SubscriptionCreatePage = lazy(() => import('src/pages/dashboard/subscription/new'));
const SubscriptionEditPage = lazy(() => import('src/pages/dashboard/subscription/edit'));
// OFFERS
const OffersProfilePage = lazy(() => import('src/pages/dashboard/offers/profile'));
const OffersCardsPage = lazy(() => import('src/pages/dashboard/offers/cards'));
const OffersListPage = lazy(() => import('src/pages/dashboard/offers/list'));
const OffersAccountPage = lazy(() => import('src/pages/dashboard/offers/account'));
const OffersCreatePage = lazy(() => import('src/pages/dashboard/offers/new'));
const OffersEditPage = lazy(() => import('src/pages/dashboard/offers/edit'));
// JOB
const JobDetailsPage = lazy(() => import('src/pages/dashboard/job/details'));
const JobListPage = lazy(() => import('src/pages/dashboard/job/list'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/job/new'));
const JobEditPage = lazy(() => import('src/pages/dashboard/job/edit'));

// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));
const FaqsPage = lazy(() => import('src/pages/dashboard/faqs'));
const PrivacyPage = lazy(() => import('src/pages/dashboard/privacy'));
const TermsPage = lazy(() => import('src/pages/dashboard/terms'));
const AboutUsPage = lazy(() => import('src/pages/dashboard/aboutUs'));
const ContactUsPage = lazy(() => import('src/pages/contact-us'));


// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'ecommerce', element: <OverviewEcommercePage /> },
      { path: 'analytics', element: <OverviewAnalyticsPage /> },
      { path: 'banking', element: <OverviewBankingPage /> },
      { path: 'booking', element: <OverviewBookingPage /> },
      { path: 'file', element: <OverviewFilePage /> },
      { path: 'Faqs', element: <FaqsPage /> },
      { path: 'terms', element: <TermsPage /> },
      { path: 'privacy', element: <PrivacyPage /> },
      { path: 'aboutUs', element: <AboutUsPage /> },
      { path: 'contactUs', element: <ContactUsPage /> },
      {
        path: 'booking',
        children: [
          { element: <BookingProfilePage />, index: true },
          { path: 'profile', element: <BookingProfilePage /> },
          { path: 'cards', element: <BookingCardsPage /> },
          { path: 'list', element: <BookingListPage /> },
          { path: 'new', element: <BookingCreatePage /> },
          { path: ':id/edit', element: <BookingEditPage /> },
          { path: 'account', element: <BookingAccountPage /> },
        ],
      },
      {
        path: 'agents',
        children: [
          { element: <AgentsProfilePage />, index: true },
          { path: 'profile', element: <AgentsProfilePage /> },
          { path: 'cards', element: <AgentsCardsPage /> },
          { path: 'list', element: <AgentsListPage /> },
          { path: 'new', element: <AgentsCreatePage /> },
          { path: ':id/edit', element: <AgentsEditPage /> },
          { path: 'account', element: <AgentsAccountPage /> },
        ],
      },
      {
        path: 'services',
        children: [
          { element: <ServicesProfilePage />, index: true },
          { path: 'profile', element: <ServicesProfilePage /> },
          { path: 'cards', element: <ServicesCardsPage /> },
          { path: 'list', element: <ServicesListPage /> },
          { path: 'new', element: <ServicesCreatePage /> },
          { path: ':id/edit', element: <ServicesEditPage /> },
          { path: 'account', element: <ServicesAccountPage /> },
        ],
      },
      {
        path: 'category',
        children: [
          { element: <CategoryProfilePage />, index: true },
          { path: 'profile', element: <CategoryProfilePage /> },
          { path: 'cards', element: <CategoryCardsPage /> },
          { path: 'list', element: <CategoryListPage /> },
          { path: 'new', element: <CategoryCreatePage /> },
          { path: ':id/edit', element: <CategoryEditPage /> },
          { path: 'account', element: <CategoryAccountPage /> },
        ],
      },
      {
        path: 'customer',
        children: [
          { element: <CustomerProfilePage />, index: true },
          { path: 'profile/:id', element: <CustomerProfilePage /> },
          { path: 'cards', element: <CustomerCardsPage /> },
          { path: 'list', element: <CustomerListPage /> },
          { path: 'new', element: <CustomerCreatePage /> },
          { path: ':id/edit', element: <CustomerEditPage /> },
          { path: 'account', element: <CustomerAccountPage /> },
        ],
      },
      {
        path: 'business',
        children: [
          { element: <BusinessProfilePage />, index: true },
          { path: 'profile', element: <BusinessProfilePage /> },
          { path: 'cards', element: <BusinessCardsPage /> },
          { path: 'list', element: <BusinessListPage /> },
          { path: 'new', element: <BusinessCreatePage /> },
          { path: ':id/edit', element: <BusinessEditPage /> },
          { path: 'account', element: <BusinessAccountPage /> },
        ],
      },
      {
        path: 'transfer',
        children: [
          { element: <TransferProfilePage />, index: true },
          { path: 'profile', element: <TransferProfilePage /> },
          { path: 'cards', element: <TransferCardsPage /> },
          { path: 'list', element: <TransferListPage /> },
          { path: 'new', element: <TransferCreatePage /> },
          { path: ':id/edit', element: <TransferEditPage /> },
          { path: 'account', element: <TransferAccountPage /> },
        ],
      },
      {
        path: 'equipments',
        children: [
          { element: <EquipmentsProfilePage />, index: true },
          { path: 'profile', element: <EquipmentsProfilePage /> },
          { path: 'cards', element: <EquipmentsCardsPage /> },
          { path: 'list', element: <EquipmentsListPage /> },
          { path: 'new', element: <EquipmentsCreatePage /> },
          { path: ':id/edit', element: <EquipmentsEditPage /> },
          { path: 'account', element: <EquipmentsAccountPage /> },
        ],
      },
      {
        path: 'subscription',
        children: [
          { element: <SubscriptionProfilePage />, index: true },
          { path: 'profile', element: <SubscriptionProfilePage /> },
          { path: 'cards', element: <SubscriptionCardsPage /> },
          { path: 'list', element: <SubscriptionListPage /> },
          { path: 'new', element: <SubscriptionCreatePage /> },
          { path: ':id/edit', element: <SubscriptionEditPage /> },
          { path: 'account', element: <SubscriptionAccountPage /> },
        ],
      },
      {
        path: 'offers',
        children: [
          { element: <OffersProfilePage />, index: true },
          { path: 'profile', element: <OffersProfilePage /> },
          { path: 'cards', element: <OffersCardsPage /> },
          { path: 'list', element: <OffersListPage /> },
          { path: 'new', element: <OffersCreatePage /> },
          { path: ':id/edit', element: <OffersEditPage /> },
          { path: 'account', element: <OffersAccountPage /> },
        ],
      },
      {
        path: 'order',
        children: [
          { element: <OrderListPage />, index: true },
          { path: 'list', element: <OrderListPage /> },
          { path: ':id', element: <OrderDetailsPage /> },
        ],
      },
      {
        path: 'job',
        children: [
          { element: <JobListPage />, index: true },
          { path: 'list', element: <JobListPage /> },
          { path: ':id', element: <JobDetailsPage /> },
          { path: 'new', element: <JobCreatePage /> },
          { path: ':id/edit', element: <JobEditPage /> },
        ],
      },
      { path: 'chat', element: <ChatPage /> },
      { path: 'permission', element: <PermissionDeniedPage /> },
      { path: 'blank', element: <BlankPage /> },
    ],
  },
];
